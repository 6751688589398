import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { Wrapper, H, P, Image } from '@farewill/ui'
import { BORDER, BREAKPOINT, COLOR, FONT } from '@farewill/ui/tokens'
import ExternalLink from 'components/ExternalLink'

const StyledBeyondHero = styled(Wrapper)`
  border-color: ${COLOR.GREY.LIGHT};
  border-radius: ${BORDER.RADIUS.L};
  box-shadow: ${BORDER.SHADOW.M};
  display: flex;
  flex-direction: column;
  @media screen and (min-width: ${BREAKPOINT.M}px) {
    flex-direction: row;
    justify-content: space-between;
  }
`

const ImageWrapper = styled(Wrapper)`
  display: flex;
  justify-content: center;
  align-items: end;
`

const StyledExternalLink = styled(ExternalLink)`
  text-decoration: none;
  font-weight: ${FONT.WEIGHT.MEDIUM};
`

const StyledTitle = styled(H)`
  font-size: 24px;
`

export default function Hero(): ReactElement {
  return (
    <StyledBeyondHero bordered background={COLOR.BACKGROUND.FOG}>
      <Wrapper padding={['M']} paddingFromL={['XL', 0, 'XL', 'XL']}>
        <Wrapper margin={[0, 0, 'M']}>
          <Image
            path="com-assets/beyond/beyond-logo"
            width={139}
            height={36}
            widthFromL={169}
            heightFromL={44}
          />
        </Wrapper>

        <StyledTitle tag="h1" margin={[0, 0, 'XS']}>
          Beyond is shutting its doors
        </StyledTitle>
        <P size="M" color={COLOR.BLACK} margin={[0, 0, 'XS']}>
          If you have questions as a current customer or charity partner,
          contact us:{' '}
          <StyledExternalLink href="mailto:beyond@farewill.com">
            beyond@farewill.com
          </StyledExternalLink>
        </P>
        <P size="M" color={COLOR.BLACK} marign={0}>
          If you’d like to write your will, get started with Farewill below.
        </P>
      </Wrapper>

      <ImageWrapper paddingFromL={[0, 'XL', 0, 'L']}>
        <Image path="com-assets/beyond/beyond-man" width={183} height={233} />
      </ImageWrapper>
    </StyledBeyondHero>
  )
}
